import React from 'react';
import "./sidebar.css"

function Sidebar({ hashtags, isOpen, onCloseSideBar }) {
    console.log(isOpen)
    return (
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <div className="closeSidebar"><a onClick={onCloseSideBar}>close</a></div>
            {hashtags.map((h, index) => <a onClick={h.onClick}><div key={index} className="sidebarOption">{h.name}</div></a>)}
        </div>
    );
}

export default Sidebar;
