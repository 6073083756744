import React, {useState} from 'react';
import './App.css';
import TweetCarousel from './components/TweetCarousel';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/sidebar';
import TweetCarouselSpecific from './components/TweetCarouselSpecific';

const sidebarOptions = [
  {onClick:() => window.location = '/hashtag/jews', name:"#يهود (#jews)"},
  {onClick:() => window.location = '/hashtag/hamas', name:"#حماس (#hamas)"},
  {onClick:() => window.location = '/country/lebanon', name:"Country Lebanon"},
  {onClick:() => window.location = '/country/egypt', name:"Country Egypt"},
  {onClick:() => window.location = '/country/jorden', name:"Country Jorden"},
  {onClick:() => window.location = '/country/kuwait', name:"Country Kuwait"},
  {onClick:() => window.location = '/country/palestine', name:"Country Palestine"},
  {onClick:() => window.location = '/country/france', name:"Country France"},
  {onClick:() => window.location = '/country/saudi_arabia', name:"Country Saudi Arabia"},
]

function App() {
  const [sideBarOpend, setNavBarOpend] = useState(false);

  const onClickHamburger = (currStateNavBar) => {
      console.log(currStateNavBar);
      setNavBarOpend(!currStateNavBar)
  }
  return (
    <div className="App">
      <div className="mainContainer">
        <Header onClickHamburger={onClickHamburger} sideBarOpend={sideBarOpend}/>
        <Sidebar hashtags={sidebarOptions} isOpen={sideBarOpend} onCloseSideBar={() => onClickHamburger(sideBarOpend)}/>
        <Router>
          <Routes>
            {/* <Route path="/" element={<Navigate replace to="/country/lebanon" />} /> */}
            <Route exact path="/" element={<TweetCarousel />} />
            <Route path="/hashtag/:specific" element={<TweetCarouselSpecific api_route="hashtag"/>} />
            <Route path="/country/:specific" element={<TweetCarouselSpecific api_route="country"/>} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
 