import React from 'react';
import "./Header.css";

const Header = ({onClickHamburger, sideBarOpend}) => {

    return (
        <div className="header">
            <a onClick={() => onClickHamburger(sideBarOpend)}><img className="hamburgerImg" src="/images/Hamburger_icon.svg" /></a>
        </div>
    );
}

export default Header;
