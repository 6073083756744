import React, { useState, useEffect, useRef } from 'react';
import './TweetCarousel.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useParams } from 'react-router-dom';

const { format } = require('date-fns');


const TweetCarouselSpecific = ({api_route}) => {
    const [tweets, setTweets] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef();
    let { specific } = useParams();

    console.log(api_route)
    const handleSlideChange = () => undefined
    useEffect(() => {
        fetch('https://api.palestinian-mindset.com/' + api_route + '/' + specific,{
        // fetch('http://localhost:5000/' + api_route + '/' + specific,{
		  method: "GET",
		  mode: "cors",
		  headers: {
		    "Content-Type": "application/json",
            'Accept-Encoding': 'gzip',
		  }
		})
	    .then(response => {console.log(response); return response; })
            .then(response => { const x = response.json(); console.log(x); return x})
            .then(data => setTweets(data['tweets']))
            .catch(err => console.error('Error fetching tweets:', err));
    }, []);

    if (!tweets || !tweets.length) return null;

    const carouselProps = {
        autoPlay: true,
        autoPlayInterval: 6000,
        infinite: true,
        disableDotsControls: true, // Disable dots (pagination)
        items: tweets.map((t) => {
            return <div className="carousel-container">
                <span className="translated-text">{t.translatedText}</span>
                <span className="original-text">{t.originalText}</span>
                <span className="date-text">{format(new Date(t['tweetDate']), 'dd/MM/yyyy HH:mm')}</span>
                {t.authorHandle !== null && <a href={'https://twitter.com/' + t.authorHandle} target="_blank" rel="noopener noreferrer">View Author's Profile</a>}
            </div>
        }),
        onSlideChanged: handleSlideChange 
    }

    return (
        <AliceCarousel ref={carouselRef} {...carouselProps}/>
    );
}

export default TweetCarouselSpecific;
